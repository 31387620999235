.fpo-login {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('https://www.feedorg.com/assets/paddy-farmer-bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 30px;
}
.login-row {
  height: 500px;
}
.login-section {
  background: white;
  padding: 60px 20px;
  position: relative;
  border-radius: 20px;
  border-bottom: 15px solid #1ca828;
}
.login-section input:not(.search-bar-input,input[type='file']),
.login-section select:not(.lng-select,.category-list),
.login-section textarea {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border-bottom: 2px solid black !important;
}
.login-section input,
.login-section select option,
.login-section textarea {
  color: black !important;
}
.login-section input::placeholder,
.login-section textarea::placeholder {
  color: black !important;
}
.login-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background: #ff5300;
}
.loginbtn {
  background: transparent;
  width: 20%;
  border: none;
  outline: none;
  border: 2px solid black;
  border-radius: 20px;
  padding: 8px;
  font-size: 18px;
  transition: all 0.5s;
}
.loginbtn:hover {
  background: #1ca828;
  color: white;
}
