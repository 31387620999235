.brd {
  border: 1px solid red;
}

.mybusiness-wrap {
  background-color: #85c6f8;
}
.mybusi-cards-wrap {
  padding: 8px;
}

.mybusi-cards {
  width: 170px;
  height: 170px;
  margin: 10px;
  border-radius: 20px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  /* border: 1px solid black; */
}

.mybusi-broadcast-btn {
  width: 550px;
  margin: 10px;
  font-size: 26px;
  font-weight: bolder;
  border-radius: 50px 50px;
  background-color: #8660a9;
  color: white;
}

.business-feed-Connect-btn {
  background-color: #8660a9 !important;
}
