.newsletter-section {
  margin: 0 auto;
  bottom: -30px;
  padding: 15px;
  background-color: white;
  color: black;
  border-radius: 15px 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 426px) {
  .newsletter-section-wrap {
    display: none;
  }
}
