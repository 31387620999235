/* .testimonial-swiper .swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
} */

.testimonialBox {
  max-height: 200px;
  background-color: blanchedalmond;
  padding: 10px;
  border-radius: 10px 10px;
  /* box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); */
}

.testimonialbox .quote {
  position: absolute;
  top: 20px;
  right: 30px;
  opacity: 0.2;
}










/* section{
	float: left;
	position: relative;
	padding: 30px 0;
	background: #fcfcfd;
	z-index: 1;
	width: 100%;
} */
.section-title{
	/* float: left; */
	/* position: relative;
	width: 100%;
	padding-bottom: 40px;  */
  text-align: center;
}
/* .section-title p{
	color: #7d93b2;
	font-size: 13px;
	line-height: 20px;
	max-width: 550px;
	margin: 0 auto;
} */
.section-title h2 {
	float: left;
	width: 100%;
	text-align: center;
	color: #007aff;
	font-size: 34px;
	font-weight: 800;
	position: relative;
}
/* .section-separator {
	float: left;
	width: 100%;
	position: relative;
	margin: 20px 0;
}
.section-separator:before{
	content: '';
	position: absolute;
	left: 50%;
	top: 0;
	height: 3px;
	width: 50px;
	border-radius: 3px;
	z-index: 2;
	background-color: #007aff;
	margin-left: -25px;
} */
.swiper-container {
	width: 100%;
	height: 100%;
}
.listing-carousel-button{
	position: absolute;
	/* top: 50%; */
	width: 50px;
	/* height: 50px; */
	line-height: 50px;
	margin-top: -25px;
	z-index: 100;
	cursor: pointer;
	background: #007aff;
	box-shadow: 0 9px 26px rgba(58, 87,135,0.45);
	transition: all 200ms linear;
	outline: none;
}
.listing-carousel-button.listing-carousel-button-next{
	right: -30px;
	padding-right: 20px;
	border-radius: 60px 0 0 60px;
}
.listing-carousel-button.listing-carousel-button-prev{
	left: -30px;
	padding-left: 20px;
	border-radius: 0 60px 60px 0;
}
.listing-carousel-button.listing-carousel-button-next:hover{
	right: -15px;
	background: rgba(6,27,65,0.4);
}
.listing-carousel-button.listing-carousel-button-prev:hover{
	left: -15px;
	background: rgba(6,27,65,0.4);
}
.testimonialCard {
	transition: all .3s ease-in-out;
	transform: scale(0.9);
	opacity: 0.9;
}
.testimonials-text {
	padding: 95px 50px 75px;
	overflow:hidden;
	/* background: #f5f6fa; */
  background-color: #1f978e;
	/* border:1px solid #f1f1f1; */
	transition: all .3s ease-in-out;
}
.testimonials-text-after{
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	position: absolute;
	color: #ccc;
	opacity: .3;
	font-size: 35px;
	transition: all 400ms linear;
	bottom: 25px;
	right: 30px;
}
.testimonials-text-before{
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	position: absolute;
	color: #ccc;
	opacity: .3;
	font-size: 35px;
	transition: all 400ms linear;
	top: 25px;
	left: 30px;
}
.testimonials-text .listing-rating{
	float: none;
	display: inline-block;
	margin-bottom: 12px;
}
.listing-rating i{
	color: #007aff;
}
.testimonials-avatar h3{
	font-weight: 600;
	color: #ffffff;
	font-size: 18px;
}
.testimonialProfession{
	 font-weight:400;
	 font-size:12px;
	 padding-top:6px;
	 color:white
}
/* .testimonials-carousel .swiper-slide{
	padding: 30px 0;
}	  */
.testimonilaAvatar{
	position: absolute;
	left: 50%;
	/* top: -30px; */
	/* width: 90px; */
	/* height: 90px; */
  padding-top: 5px;
	margin-left: -45px;
	z-index: 20;
  
}
.testimonilaAvatar img{
	/* width: 90px;
	height: 90px; */
	float: left;
	border-radius: 100%;
  background-color: white;
	border:6px solid #fff;
	box-shadow: 0 9px 26px rgba(58, 87,135,0.1);
}
/* .swiper-slide-active .testimonials-text {
	background: #fff;
	box-shadow: 0 9px 26px rgba(58, 87,135,0.1);
} */
.testimonials-text p{
	color: white;
	font-size: 14px;
	/* font-family: Georgia, "Times New Roman", Times, serif; */
	font-style: italic;
	line-height: 24px;
	padding-bottom: 10px;
	font-weight: 500;
}
.text-link{
	position: absolute;
	bottom:0;
	padding: 15px 0;
  text-align: center;
	border-radius: 10px 10px 0 0;
	background: #f9f9f9;
	border:1px solid #eee;
	box-shadow: 0 10px 15px rgba(0,0,0,0.03);
	left: 50%;
	width: 200px;
	margin-left: -100px;
}
.swiper-slide-active .testimonialCard{
	opacity: 1;
	transform: scale(1.0);
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.swiper-slide-active .testimonials-text {
  border-radius: 20px;
}

/* .swiper-slide-active .testimonialCard .testimonilaAvatar {
  padding: 10px;
} */
.swiper-slide-active .testimonialCard .testimonialMessage {
  padding: 15px;
}

.swiper-slide-prev .testimonialCard {
  opacity: 0.4;
}

.swiper-slide-next .testimonialCard {
  opacity: 0.4;
}

/* .swiper-slide-duplicate .testimonialCard {
  opacity: 0.1;
} */
.tc-pagination{
	float: left;
	margin-top: 10px;
	width: 100% !important;
}
.tc-pagination_wrap {
	position: absolute;
	bottom: -40px;
	left: 0;
	width: 100%;
}
.tc-pagination2{
	float: none;
	display: inline-block;
	padding: 14px 0;
	background: #fff;
	border-radius: 30px;
	min-width: 250px;
	border-bottom: 0;
}
.tc-pagination .swiper-pagination-bullet, .tc-pagination2.swiper-pagination-bullet{
	opacity: 1;
	background: #384f95;
	margin:0 2px;
	width: 10px;
	height: 10px;
	transition: all 300ms ease-in-out;
}