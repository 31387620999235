.brd {
  border: 1px solid red;
}
.myfpo-home {
  padding: 5px;
  background-color: #f8ffbe;
}
.fpo-icon-img {
  border-radius: 50px 50px;
  border: 1px solid black;
}

.fpo-cards-wrap .nav-link {
  color: black !important;
}
