.fpo-register {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('http://www.feedorg.com/assets/paddy-farmer-bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 30px;
}
.register-section {
  background: white;
  padding: 60px 20px;
  position: relative;
  border-radius: 20px;
  border-bottom: 15px solid #1ca828;
}
.reg-cover{
  display:none;
  justify-content:center;
  align-items: center;
  z-index:3000;
  background:white;
  position:absolute;
  bottom:0;
  top:0;
  left:0;
  right:0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  opacity:0.8;
}
.register-section input:not(.search-bar-input,input[type='file']),
.register-section select:not(.lng-select,.category-list),
.register-section textarea {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border-bottom: 2px solid black !important;
}
.register-section input,
.register-section select option,
.register-section textarea {
  color: black !important;
}
.register-section input::placeholder,
.register-section textarea::placeholder {
  color: black !important;
}
.reg-header {
  z-index:4000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background: #ff5300;
}
.regbtn {
  background: transparent;
  width: 20%;
  border: none;
  outline: none;
  border: 2px solid black;
  border-radius: 20px;
  padding: 8px;
  font-size: 18px;
  transition: all 0.5s;
}
.regbtn:hover {
  background: #1ca828;
  color: white;
}
.otp-overlay{
  display:none;
  justify-content:center;
  align-items:center;
  background:rgba(0,0,0,0.5);
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:8000;
}
.otp-popup{
  padding:40px;
  width:400px;
  height:200px;
  background:white;
  border-radius:20px;
}
.otp-popup button{
  border:none;
  outline:none;
  border-radius:10px;
  color:white;
  padding:10px 15px;
  background:#ff5300;
}
#validateotp-form{
  display:none;
}