.trade-content .graph-bg{
    position:relative;
    width:100%;
    height:350px;
    background-image:url('https://www.feedorg.com/assets/product-profile/candlesticks.png');
    background-size: cover;
    background-position: center center;
    background-repeat:no-repeat;
}
.trade-content{
    padding-left:0px!important;
    padding-right:0px!important;
}
.trade-content .text-content-wrapper{
    width:60%;
    height:auto;
    padding:25px!important;
    background:white;
    border-radius:50px;
    position:absolute;
    left:calc(50% - 30%);
    bottom:-60px;
    box-shadow:2px 2px 5px 2px rgba(0,0,0,0.1);
}
.trade-content .text-content-wrapper p{
    font-weight:500;
}
.trade-content .text-content-wrapper .trade-top-left-border{
    position:absolute;
    left:0;
    top:0;
    height:290px;
}
.trade-content .text-content-wrapper .trade-bottom-right-border{
    position:absolute;
    right:0;
    bottom:0;
}
.header-titles-wrapper{
    width:70%;
    margin-top:8rem!important;
    margin-left:auto!important;
    margin-right:auto!important;
}
.header-titles-wrapper .header-title-one,.header-title-two{
    position:relative;
    width:30%;
    height:60px;
    border-radius:50px;
    background-image: linear-gradient(to right, #0161ea, #0078f3, #008df8, #00a0f9, #00b2f8);
}
.header-title-one h5,.header-title-two h5{
    color:white;
}
.header-titles-wrapper .globe{
    position: absolute;
}
.header-titles-wrapper img.left-globe{
    right:-20px;
}
.header-titles-wrapper img.right-globe{
    left:-20px;
}
.note-points-wrapper{
    width:85%;
    margin-left:auto!important;
    margin-right:auto!important;
}
.note-points-wrapper ul li{
    padding:12px!important;
    margin-bottom:10px;
    list-style-type: none;
    border-radius:50px;
    background:white;
    box-shadow:2px 2px 5px 2px rgba(0,0,0,0.1);
}
.note-points-wrapper ul li span{
    font-weight:600;
    color:#2e3192;
}
.note-points-wrapper ul li span::before{
    content:'';
    display:inline-block;
    width:8px;
    height:8px;
    border-radius:50%;
    background:#ed1c24;
    margin-right:10px!important;
}