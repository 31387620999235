.brd {
  border: 1px solid red;
}

/* carousel section */
.carousel-section {
  /* position: relative; */
  /* top: 240px; */
}
.carousel-inner {
  height: 565px;
  /* border-radius: 0px 0px 20px 20px; */
}

.carousel-section .carousel-inner img {
  height: 100% !important;
  animation: thing 20s;
}

@keyframes thing {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}

/* How FEED Works */
.section-heading {
  margin: 10px;
  padding: 2px;
  background-color: orange;
  color: white;
  border-radius: 10px 10px;
}

/* Media Queries */

@media only screen and (max-width: 426px) {
  .carousel-section {
    display: none !important;
  }

  .resp-events-view {
    display: none;
  }
}
@media only screen and (min-width: 427px) and (max-width: 768px) {
  /* carousel section */
  .carousel-inner {
    height: 250px;
  }

  .carousel-section .carousel-inner img {
    height: 100% !important;
    animation: thing 20s;
  }
}
