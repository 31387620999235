.brd {
  border: 1px solid red;
}
/* .servicenav-wrap {
    border-radius: 20px 20px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
} */
.violet-btn {
  background-color: #8761aa;
}
.farm-btn {
  background-color: #a4cf3e;
}

.export-btn {
  background-color: #64c5bc;
}

.wheat-btn {
  background-color: #dd8444;
}

.merun-btn {
  background-color: rgb(156, 57, 39);
}

.nav-btn .nav-link {
  padding: 0rem !important;
}

@media only screen and (max-width: 425px) {
  .resp-ServiceNav {
    display: none;
  }
}
