.aboutus-section {
  /* margin-top: 5px; */
}

.about-content-wrap {
  background-color: #d2774b;
  color: white;
  font-weight: bold;
}

.objective-content-wrap {
  background-color: #4a9c42;
  color: white;
}

.vision-title {
  font-size: 32px !important;
  font-weight: bold;
}
.mission-title {
  font-size: 32px !important;
  font-weight: bold;
}

@media only screen and (max-width: 426px) {
  .about-content-wrap {
    display: none !important;
  }
  .vision-mission-wrap {
    display: none !important;
  }
  .objective-content-wrap {
    background-color: #b6bbfc !important;
    color: black;
  }
  .objective-title {
    font-size: 16px;
  }
  .objective-content {
    font-size: 12px;
  }
}

@media only screen and (min-width: 427px) and (max-width: 768px) {
  .aboutus-title {
    font-size: 24px;
  }
  .aboutus-content {
    font-size: 14px !important;
    font-weight: 500;
  }
  .objective-title {
    font-size: 24px;
  }
  .objective-content {
    font-size: 14px !important;
    font-weight: 500;
  }
  .vision-title {
    font-size: 22px !important;
    font-weight: bold;
  }
  .vision-content {
    padding: 0rem !important;
    font-size: 14px;
  }
  .vision-card .card-header {
    padding: 0rem !important;
  }
  .vision-card img {
    width: 100px;
  }
  .mission-card img {
    width: 100px;
  }
  .mission-card .card-header {
    padding: 0rem !important;
  }
  .mission-title {
    font-size: 22px !important;
    font-weight: bold;
  }
  .mission-content {
    padding: 0rem !important;
    font-size: 14px;
  }
}
.oceanWrap {
  /* position:relative;
  text-align:center; */
  /* height: 400px; */
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
  /* border: 1px solid red; */
  height: 100px;
  /* border-radius: 25px 25px; */
}

.inner-header {
  height:35vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:100%;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:150px;
  max-height:150px;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -5s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}

.shipmove {
  animation: shipmove 25s linear infinite;
}

@keyframes shipmove {
  0%,100% {
    transform: translateX(20%)rotate(-1deg) rotateX(5deg);
  }
  100% {
    transform: translateX(900px);
  }
}
