.biproducts-table table{
    width:100%;
}
.biproducts-table table th{
    text-align:center;
    vertical-align: middle;
    border:1px solid black;
}
.biprods-row th{
    background:#8dc63f;
}
.biprods-text-wrapper{
    width:80%;
    background:white;
    padding:20px!important;
    border-radius: 20px;
    box-shadow:2px 2px 20px 2px rgba(0,0,0,0.1);
}
.biprods-text-wrapper .more-info{
    color:#2e3192;
}
.biprods-text-wrapper{
    color:black;
    font-weight:bold;
}