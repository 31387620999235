*{
    margin:0px;
    padding:0px;
}
.sections a{
    text-decoration: none!important;
    color:black!important;
}
.form-div{
    padding-top:10px!important;
}
.form{
    width:400px;
    height:350px;
    background-image: linear-gradient(to bottom, #70d0f6, #93d9f6, #b1e2f7, #ccebf8, #e7f4f9);
    border-radius:20px;
    padding:20px!important;
    margin:0 auto!important;
}
.form input{
    border:none!important;
    outline:none!important;
    border-radius:20px!important;
}
.form label{
    font-weight:bold!important;
}
.form button{
    border:none;
    outline:none;
    width:40%;
    padding:10px!important;
    color:white;
    background:#ee1c24;
    border-radius:10px;
    font-weight:bold;
}
.connect-to-feed button{
    border:none;
    outline:none;
    width:100%;
    padding:10px!important;
    color:black;
    background:#00baf2;
    border-radius:10px;
    font-weight:bold;
}
.sections .card{
    width:250px;
    height:200px;
    border-radius:20px;
    margin:10px 0px 20px 40px;
}
.db-sec{
    width:300px;
    height:250px;
    border-radius:20px;
    margin:10px auto 30px auto!important;
}
.sections .card-header,.db-sec .card-header{
    color:black;
    font-weight:bold;
    text-align:center;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
}
.sections .card-body,.db-sec .card-body{
    padding:5px 0px 0px!important;
}
.sections .card-body ul,.db-sec .card-body ul{
    padding:0px 0px 0px 10px!important;
}
.sections .card-body li,.db-sec .card-body li{
    list-style-type:none;
    font-weight:bold;
    margin-bottom:10px;
}
.sections .card-body .icon img{
    width:80px;
    height:90px;
}
.sec-1 .card-header,.sec-2 .card-header,.sec-3 .card-header{
    background:#ffcb05;
}
.sec-4 .card-header,.sec-5 .card-header,.sec-6 .card-header{
    background:#c7ebfc;
}
.sec-7 .card-header,.sec-8 .card-header,.sec-9 .card-header{
    background:#bfd730;
}
.sec-7 .card-body li{
    font-size:14px;
}
.sec-9 .card-body li{
    font-size:14px;
}
.sec-1 img,.sec-2 img,.sec-4 img{
    position:absolute;
    right:20px;
}
.ant img{
    height:280px;
}
@media only screen and (min-width:801px) and (max-width:1550px)
{
    .form{
        width:300px;
        height:325px;
    }
    .form label{
        font-size:13px;
    }   
    .form input{
        height:30px;
    }
    .form button{
        padding:3px!important;
    }
    .form-div .sections{
        padding:0px!important;
    }
    .sections .card{
        width:200px;
        height:180px;
        border-radius:20px;
        margin:10px 0px 20px 10px;
    }
    .sections .card-header{
        padding:10px;
    }
    .sections .card-header h5{
        font-size:14px;
    }
    .sections .card-body .icon img{
        width:60px;
        height:70px;
    }
    .sections .card-body li{
        font-size:11px;
    }
}